import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { parse, isValid, isBefore, isAfter } from "date-fns";

const formatDobValue = (value) => {
  if (!value) {
    return "";
  }

  const input = value.replace(/\D/g, "");

  let formattedInput;
  if (input.length <= 2) {
    formattedInput = input; // Just day
  } else if (input.length <= 4) {
    formattedInput = `${input.slice(0, 2)}/${input.slice(2)}`; // Day and month
  } else if (input.length <= 6) {
    formattedInput = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(
      4
    )}`; // Day, month, and partial year
  } else if (input.length <= 8) {
    formattedInput = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(
      4
    )}`; // Full date with day/month/year
  }

  return formattedInput;
};

const validateDate = (dateString, format) => {
  const parsedDate = parse(dateString, format, new Date());

  if (!isValid(parsedDate)) {
    return false;
  }

  const minDate = new Date(1920, 0, 1); // January 1, 1920
  const today = new Date(); // Current date

  return isAfter(parsedDate, minDate) && isBefore(parsedDate, today);
};

export default function emailPasswordConfig() {
  return EmailPassword.init({
    signInAndUpFeature: {
      signUpForm: {
        formFields: [
          {
            id: "firstName",
            label: "First Name",
            placeholder: "John",
            optional: true,
          },
          {
            id: "lastName",
            label: "Last Name",
            placeholder: "Doe",
            optional: true,
          },
          {
            id: "dob",
            label: "Date Of Birth",
            optional: true,
            validate: (value) => {
              if (!value) {
                return;
              }

              const format = "dd/MM/yyyy";
              const isValidDate = validateDate(value, format);

              if (!isValidDate) {
                return "Invalid date. Please enter a valid date";
              }

              return;
            },
            inputComponent: ({ value, name, onChange }) => (
              <div data-supertokens="inputContainer">
                <div data-supertokens="inputWrapper ">
                  <input
                    autoComplete="off"
                    data-supertokens="input input-dob"
                    className="supertokens-input"
                    type="text"
                    name={name}
                    placeholder="dd/mm/yyyy"
                    maxLength={10}
                    value={formatDobValue(value)}
                    onChange={(e) => onChange(e.target.value)}
                  />
                </div>
              </div>
            ),
          },
          {
            id: "phone",
            label: "Mobile Number",
            placeholder: "+12223334455",
            optional: true,
          },
          {
            id: "username",
            label: "Username",
            placeholder: "Select username",
          },
          {
            id: "terms",
            label: "",
            optional: false,
            nonOptionalErrorMsg: "Field is not optional",
            inputComponent: ({ name, onChange }) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  marginBottom: "-12px",
                }}
              >
                <input
                  name={name}
                  type="checkbox"
                  onChange={(e) => onChange(e.target.checked.toString())}
                />
                <span style={{ marginLeft: 5 }}>
                  I agree to the{" "}
                  <a
                    href="https://maryapp.io/terms-and-conditions"
                    data-supertokens="link"
                  >
                    Terms and Conditions
                  </a>
                </span>
              </div>
            ),
          },
        ],
      },
    },
  });
}
